import React from "react";
import {
  RiFacebookFill,
  RiLinkedinFill,
  RiPhoneLine,
  RiMailLine,
  RiGithubFill,
} from "react-icons/ri";
import { MdOutlineLocationOn } from "react-icons/md";
import profileImage from "../assets/images/profile2.jpg";
import LinkButton from "./Components/LinkButton";
import useScrollTop from "../hooks/useScrollTop";

const Home = () => {
  useScrollTop();
  return (
    <div className="text-gray-800">
      <div className="grid grid-cols-3 gap-6 mb-[50px] md:mb-[100px] bg-shadow-dark-blue px-16 pt-16">
        {/* ------ profile -------- */}
        <div className="max-w-[280px] mb- md:-mb-[60px] col-span-3 md:col-span-1 justify-self-center md:justify-self-start rounded-lg overflow-hidden">
          <img src={profileImage} alt="" className="object-center h-full" />
        </div>
        {/* -------- title -------- */}
        <div className="col-span-3 md:col-span-2 text-center flex justify-center items-center text-slate-100 mb-10">
          <div className="font-serif ">
            <h1 className="font-semibold text-2xl sm:text-4xl">Kaiser Ahmed</h1>
            <p className="py-3 font-medium text-lg sm:text-xl">Web Developer</p>
            <div className="flex justify-center gap-2">
              <SocialIcon href="https://www.facebook.com/kaiser.ahmed.5059/">
                <RiFacebookFill className="h-6 w-6 text-light-blue" />
              </SocialIcon>
              <SocialIcon href="https://www.linkedin.com/in/md-kaiser-ahmed-347767239/">
                <RiLinkedinFill className="h-6 w-6 text-light-blue" />
              </SocialIcon>
              <SocialIcon href="https://github.com/ahmed-kaiser">
                <RiGithubFill className="h-6 w-6 text-black" />
              </SocialIcon>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-10 px-8 md:px-16 pb-16">
        {/* ------ about section -------- */}
        <div className="col-span-2 md:col-span-1">
          <h2 className="text-shadow-dark-blue text-2xl font-semibold">
            About <span className="text-light-blue">Me</span>
          </h2>
          <p className="max-w-xl text-justify py-3 text-gray-600">
            Hello World! I am a web developer. I enjoy programming very much and
            building web applications. Right now I am devoting my time to
            working on the MERN stack development and learning it in the depth.
          </p>
          <LinkButton href="https://drive.google.com/file/d/1_zs2QvMUaZ6HsfmPwbGHMrinzykCoBqW/view?usp=sharing">
            Resume
          </LinkButton>
        </div>
        {/* ------ contact info section ------ */}
        <div className="col-span-2 md:col-span-1">
          <ul className="space-y-3 divide-y divide-gray-200 p-2 rounded-md text-gray-400">
            <li className="flex items-center gap-1">
              <div className="p-2 rounded-md">
                <RiPhoneLine className="h-5 w-5 text-green-600" />
              </div>
              <div>
                <small>Phone</small>
                <p>+880 1515285598</p>
              </div>
            </li>
            <li className="flex items-center gap-1">
              <div className="p-2 rounded-md">
                <RiMailLine className="h-5 w-5 text-cyan-600" />
              </div>
              <div>
                <small>Email</small>
                <p>ahmedkaiser.001@gamil.com</p>
              </div>
            </li>
            <li className="flex items-center gap-1">
              <div className="p-2 rounded-md">
                <MdOutlineLocationOn className="h-5 w-5 text-red-700" />
              </div>
              <div>
                <small>Location</small>
                <p>Chattogram, Bangladesh</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const SocialIcon = ({ children, href }) => {
  return (
    <div className="p-1 bg-gray-200 hover:bg-sky-100 rounded-full cursor-pointer duration-300">
      <a href={href} target="-blank">
        {children}
      </a>
    </div>
  );
};

export default Home;
