import { Link } from "react-router-dom";
import {projectData} from '../assets/data/projectData.js';
import useScrollTop from "../hooks/useScrollTop.js";

const Projects = () => {
  useScrollTop();
  const projects = projectData;

  return (
    <>
      <div className="bg-shadow-dark-blue py-4 text-center">
        <h2 className="text-slate-100 text-xl font-semibold">My Projects</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-items-center gap-4 py-6 px-2">
        {projects.map((project, index) => (
          <Card key={index} project={project} />
        ))}
      </div>
    </>
  );
};

const Card = ({ project }) => {
  return (
    <div className="p-2 border border-slate-200 rounded-md shadow-md overflow-hidden max-w-sm">
      <img src={project.images[0]} alt="" className="h-40 md:h-52 w-full" />
      <div className="mt-3 px-2 pb-3">
        <h3 className="font-medium text-lg text-light-blue">{project.title}</h3>
        <p className="text-sm py-1 text-justify">
          {project.headline.slice(0, 80)}...
        </p>
        <div className="flex gap-2 mt-2 justify-center items-center">
          <a
            className="bg-shadow-dark-blue rounded w-20 py-1 font-medium text-gray-200 text-sm text-center shadow-md"
            href={project.liveLink}
            target="blank"
          >
            Live
          </a>
          <Link
            to={`/projects/${project.id}`}
            className="bg-shadow-dark-blue rounded w-20 py-1 font-medium text-gray-200 text-sm text-center shadow-md"
          >
            Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Projects;
