import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";

const Root = () => {
  return (
    <div className="bg-slate-100">
      <Navbar />
      <main className="min-h-[calc(100vh-150px)] mx-2">
        <section className="max-w-screen-xl mx-auto rounded-md my-6 bg-white overflow-hidden">
          <Outlet />
        </section>
      </main>
      <Footer />
      <Toaster position="top-center" />
    </div>
  );
};

export default Root;
