import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Blog from "../pages/Blog";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import ProjectDetails from "../pages/ProjectDetails";
import Projects from "../pages/Projects";
import Root from "../pages/Root";
import Skills from "../pages/Skills";

const Routes = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: '/skills',
                    element: <Skills />
                },
                {
                    path: '/projects',
                    element: <Projects />
                },
                {
                    path: '/contact-me',
                    element: <Contact />
                },
                {
                    path: '/blog',
                    element: <Blog />
                },
                {
                    path: '/projects/:id',
                    element: <ProjectDetails />
                }
            ]
        }
    ]);

    return (
        <RouterProvider router={router} />
    );
};

export default Routes;