import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";
import { useState } from "react";
import useScrollTop from "../hooks/useScrollTop";
import {
  MdOutlineEmail,
  MdOutlineMessage,
  MdPersonOutline,
} from "react-icons/md";

const Contact = () => {
  useScrollTop();
  const [buttonState, setButtonState] = useState(false);
  const form = useRef();

  let SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  let TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  let PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const onSubmit = (e) => {
    e.preventDefault();
    setButtonState(true);
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        toast.success("Message Send Successfully");
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        toast.error("Something  wrong..!");
      },
      setButtonState(false)
    );
  };
  return (
    <>
      <div className="bg-shadow-dark-blue py-4 text-center">
        <h2 className="text-slate-100 text-xl font-semibold">Contact Form</h2>
      </div>
      <div className="max-w-md mx-auto p-6 mt-6 mb-16">
        <form ref={form} onSubmit={onSubmit} className="space-y-5">
          <div className="flex items-center border-b-2 border-slate-100">
            <label className="text-gray-600 bg-slate-100 p-1">
              <MdPersonOutline className="h-8 w-8" />
            </label>
            <input
              type="text"
              name="name"
              placeholder="Mr. xyz"
              required
              className="px-2 py-1 outline-none"
            />
          </div>
          <div className="flex items-center border-b-2 border-slate-100">
            <label className="text-gray-600 bg-slate-100 p-1">
              <MdOutlineEmail className="h-8 w-8" />
            </label>
            <input
              type="email"
              name="email"
              placeholder="xyz@gmail.com"
              required
              className="px-2 py-1 outline-none"
            />
          </div>
          <div className="flex border-b-2 border-slate-100">
            <label className="text-gray-600 bg-slate-100 p-1">
              <MdOutlineMessage className="h-8 w-8" />
            </label>
            <textarea
              name="message"
              placeholder="Your message...."
              required
              rows="4"
              className="px-2 py-1 outline-none w-full"
            ></textarea>
          </div>
          <div className="text-center pt-6">
            <button
              type="submit"
              value="Send"
              disabled={buttonState}
              className={`bg-shadow-dark-blue py-1 px-6 rounded-md font-medium duration-500 uppercase shadow-md ${
                buttonState ? "text-gray-700" : "text-gray-200 hover:text-white"
              }`}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
