import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { projectData } from "../assets/data/projectData.js";
import { RxDotFilled } from "react-icons/rx";
import { PhotoView } from "react-photo-view";
import useScrollTop from "../hooks/useScrollTop.js";

const ProjectDetails = () => {
  useScrollTop();
  const [project, setProject] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const params = useParams();

  const handleChangeImage = (link) => {
    setShowImage(link);
  };

  useEffect(() => {
    const project = projectData.find((project) => project.id === params.id);
    setProject(project);
    setShowImage(project.images[0]);
  }, [params.id]);

  if (!project) {
    return <div>Loading....</div>;
  }

  return (
    <>
      <div className="bg-shadow-dark-blue py-4 text-center">
        <h2 className="text-slate-100 text-xl font-semibold">
          {project.title}
        </h2>
      </div>
      <div className="px-2 py-6">
        <div className="mb-6 flex flex-col items-center gap-2">
          <div className="max-w-lg h-64 md:h-80">
            <img
              src={showImage}
              alt=""
              className="object-cover h-full border"
            />
          </div>
          <div className="flex flex-wrap justify-center gap-1">
            {project.images.map((image) => (
              <img
                key={image}
                onClick={() => handleChangeImage(image)}
                src={image}
                alt=""
                className="w-[100px] h-[100px] border cursor-pointer"
              />
            ))}
          </div>
        </div>
        {/* ---- project feature ------ */}
        <Section title="Feature" data={project.description} />
        {/* ---- project used technology ------ */}
        <Section title="Used Technology" data={project.usedTechnology} />
        {/* ------ links ------ */}
        <div className="my-3 px-4">
          <h4 className="text-lg font-semibold border-b text-lightest-blue capitalize">
            All Links
          </h4>
          <div className="space-y-1 mt-2">
            <Link title="Live Site" href={project.liveLink} />
            <Link title="Github(Client)" href={project.githubClient} />
            <Link title="Github(Server)" href={project.githubServer} />
          </div>
        </div>
      </div>
    </>
  );
};

const Section = ({ title, data }) => {
  return (
    <div className="my-3 px-4">
      <h4 className="text-lg font-semibold border-b text-lightest-blue capitalize">
        {title}
      </h4>
      <ul className="mt-2 max-w-3xl">
        {data?.map((point) => (
          <li key={point} className="flex">
            <span className="w-6">
              <RxDotFilled className="h-5 w-5" />
            </span>
            <span>{point}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Link = ({ title, href }) => {
  return (
    <div>
      <span className="font-semibold text-gray-600 mr-2">{title}:</span>
      <a href={href} target="blank" className="text-light-blue underline">
        {href}
      </a>
    </div>
  );
};

export default ProjectDetails;
