import React from 'react';

const Footer = () => {
    return (
        <footer className='text-center text-sm text-gray-500 py-5 px-2'>
            <p>@2022 All right reserved. by Md. Kaiser Ahmed</p>
        </footer>
    );
};

export default Footer;