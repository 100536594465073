import React from "react";
import { NavLink } from "react-router-dom";
import { RiMenuFill, RiCloseLine } from 'react-icons/ri';
import { useState } from "react";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const menu = [
    { title: "home", to: "/" },
    { title: "skills", to: "/skills" },
    { title: "project", to: "/projects" },
    { title: "contact me", to: "/contact-me" },
    { title: "blog", to: "/blog" },
  ];

  const handleMenuIconClick = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuClick = () => {
    setShowMenu(false);
  };

  return (
    <nav className="bg-slate-100 dark:bg-slate-800 sticky top-0 z-30 px-4 h-14">
      <div className="max-w-screen-xl mx-auto flex items-center h-14">
        <div onClick={handleMenuIconClick} className="md:hidden">
          {
            showMenu? <RiCloseLine className="h-6 w-6" /> : <RiMenuFill className="h-6 w-6" />
          }
        </div>
        <h1 className="grow md:grow-0 text-center font-semibold text-shadow-dark-blue dark:text-white text-2xl uppercase">kaiser Ahmed</h1>
        {/* ---- menu for large screen ------ */}
        <ul className="grow hidden md:flex justify-center items-center gap-3">
          {menu.map((item, index) => (
            <NavItem key={index} to={item.to}>
              {item.title}
            </NavItem>
          ))}
        </ul>
        {/* ------ menu for small screen ------ */}
        <ul className={`absolute md:hidden bg-slate-100 top-14 w-[220px] h-screen space-y-3 pt-4 pl-2 ${showMenu? 'left-0' : '-left-[320px]'} duration-500`}>
          {menu.map((item, index) => (
            <NavItem key={index} to={item.to} handleClick={handleMenuClick}>
              {item.title}
            </NavItem>
          ))}
        </ul>
      </div>
    </nav>
  );
};

const NavItem = ({ to, children, handleClick }) => {
  return (
    <li onClick={handleClick}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `py-1 capitalize mx-2 text-gray-600 dark:text-gray-300 ${
            isActive
              ? "border-b-2 border-lightest-blue font-medium"
              : "hover:border-b-2 hover:border-lightest-blue"
          }`
        }
        end
      >
        {children}
      </NavLink>
    </li>
  );
};

export default Navbar;
