export const projectData = [
  {
    id: "p01",
    title: "Mr.Chef",
    headline:
      "Mr.Chef is a single-page web application with backend. This is a personal service based and review taking application.",
    images: [
      "https://i.ibb.co/J2Wpwz0/p1.png",
      "https://i.ibb.co/RDPtnTZ/mr-chef-1.png",
      "https://i.ibb.co/6tmLr6z/mr-chef-2.png",
      "https://i.ibb.co/mby41mw/mr-chef-3.png",
      "https://i.ibb.co/HFBDx91/mr-chef-4.png",
    ],
    liveLink: "https://mr-chef-3ec0d.web.app/",
    githubClient: "https://github.com/ahmed-kaiser/mr-chef-client.git",
    githubServer: "https://github.com/ahmed-kaiser/mr-chef-server.git",
    description: [
      "User can sign-in and sign-up through email and password or using Google account.The system can block access for unauthorized users from accessing the private route and can direct them to the sign-in page for authorization.",
      "Users can create, view, edit and delete all his/her reviews.",
    ],
    usedTechnology: [
      "ReactJS",
      "React-router-dom",
      "Tailwind",
      "Firebase",
      "Node.js",
      "Express.js",
      "MongoDB",
    ],
  },
  {
    id: "p02",
    title: "BuyCycle",
    headline:
      "BuyCycle is a single-page full-stack web application. Where anyone can buy and sell their old bicycle.",
    images: [
      "https://i.ibb.co/9Z3vb2d/p7.png",
      "https://i.ibb.co/VDDThrD/buy-cycle-1.png",
      "https://i.ibb.co/wgHgfc7/buy-cycle-2.png",
      "https://i.ibb.co/6YwK1bz/buy-cycle-3.png",
      "https://i.ibb.co/cTCfVrG/buy-cycle-4.png",
      "https://i.ibb.co/jkpcBcz/buy-cycle-5.png",
      "https://i.ibb.co/GCL11DX/buy-cycle-6.png",
    ],
    liveLink: "https://buy-cycle-client.web.app/",
    githubClient: "https://github.com/ahmed-kaiser/buy-cycle-client.git",
    githubServer: "https://github.com/ahmed-kaiser/buy-cycle-server.git",
    description: [
      "Feature keyword - Sign-In | Sign-Up | Google Sign-In | User Authentication | User Authorization | Booking | Wishlist | Report | Make Payment | Create | Read | Update | Delete.",
      "Buyers can book or report a bicycle and also add a bicycle to his/her wish list. From the buyer dashboard, a buyer can perform payment, delete bookings and delete items from the wishlist.",
      "Sellers can add a product, delete a product and send a product for advertising. The advertised product is shown on the home page. If a product is sold out it gets removed from the advertising section automatically. The seller can view all his/her added products and booking details.",
      "Admin can view and delete all types of users. In the seller's list, there is a verification option to verify sellers. Admin can view all the reports made by buyers and also delete those.",
    ],
    usedTechnology: [
      "ReactJS",
      "React-router-dom",
      "Tailwind",
      "Firebase",
      "Node.js",
      "Express.js",
      "MongoDB",
      "Stripe",
    ],
  },
  {
    id: "p03",
    title: "LearnIt",
    headline:
      "Learnit is a web-based single-page application, which is mainly designed and developed based on the e-learning platform.",
    images: [
      "https://i.ibb.co/QYp8RSt/p2.png",
      "https://i.ibb.co/JCpRfjJ/learn-it-1.png",
      "https://i.ibb.co/bm3GsPj/learn-it-2.png",
      "https://i.ibb.co/Tcy15rx/learn-it-3.png",
      "https://i.ibb.co/fxyCGsK/learn-it-4.png",
      "https://i.ibb.co/d6k0VJJ/learn-it-5.png",
    ],
    liveLink: "https://learn-it-client.web.app/",
    githubClient: "https://github.com/ahmed-kaiser/learn-it-client.git",
    githubServer: "https://github.com/ahmed-kaiser/learn-it-server.git",
    description: [
      "The website can view courses based on different categories.",
      "Users can sign-in or sign-up through email and password or using social networks Google and GitHub. The system can block access for unauthorized users from accessing the private route and can direct them to the sign-in page for authorization.",
      "Users can update or change some profile information like name, image, and password.",
    ],
    usedTechnology: [
      "ReactJS",
      "React-router-dom",
      "Tailwind",
      "Firebase",
      "Node.js",
      "Express.js",
    ],
  },
  {
    id: "p04",
    title: "QuizLet",
    headline:
      "Quizlet is a single page application on quiz taking, web application.",
    images: [
      "https://i.ibb.co/KbLQdFg/p3.png",
      "https://i.ibb.co/cbhN5vY/quizlet-1.png",
      "https://i.ibb.co/WgrTrN3/quizlet-2.png",
      "https://i.ibb.co/B3sCy9M/quizlet-3.png",
    ],
    liveLink: "https://leafy-bombolone-bd1e98.netlify.app",
    githubClient: "https://github.com/ahmed-kaiser/quizlet.git",
    githubServer: "",
    description: [
      "This app is made up of react. For routing used react-router and for the design part used tailwind.",
      "The quiz section is designed in a multi-step format. Users have to answer the present quiz to proceed further. After submission, the system will show the final result.",
      "In every quiz, there is an answer viewing section, and If a user selects an option the system will instantly notify the user the selected option is right or wrong through a tooltip.",
      "This app has a statistic page that shows the quiz topic and the number of quizzes in each through a Barchart. Here the chart is implemented by rechart package.",
    ],
    usedTechnology: ["ReactJS", "React-router-dom", "Tailwind", "Rechart"],
  },
];
