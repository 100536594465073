import React from "react";
import useScrollTop from "../hooks/useScrollTop";

const Blog = () => {
  useScrollTop();
  return (
    <>
      <div className="bg-shadow-dark-blue py-4 text-center">
        <h2 className="text-slate-100 text-xl font-semibold">Blog</h2>
      </div>
      <div className="min-h-[400px] flex items-center justify-center">
        <p className="text-center font-medium text-gray-400">No blog posted yet</p>
      </div>
    </>
  );
};

export default Blog;
