import htmlImg from "../assets/images/skills/html.png";
import cssImg from "../assets/images/skills/css.png";
import bootstrapImg from "../assets/images/skills/bootstrap.png";
import tailwindImg from "../assets/images/skills/tailwind.png";
import reactImg from "../assets/images/skills/react.png";
import nodeImg from "../assets/images/skills/node.png";
import firebaseImg from "../assets/images/skills/firebase.png";
import githubImg from "../assets/images/skills/github.png";
import mongodbImg from "../assets/images/skills/mongodb.png";
import pythonImg from "../assets/images/skills/python.png";
import useScrollTop from "../hooks/useScrollTop";

const skills = [
  { title: "HTML", img: htmlImg, type: "frontend" },
  { title: "CSS", img: cssImg, type: "frontend" },
  { title: "Bootstrap", img: bootstrapImg, type: "frontend" },
  { title: "Tailwind", img: tailwindImg, type: "frontend" },
  { title: "ReactJS", img: reactImg, type: "frontend" },
  { title: "Node.js", img: nodeImg, type: "backend" },
  { title: "Firebase", img: firebaseImg, type: "backend" },
  { title: "MongoDB", img: mongodbImg, type: "backend" },
  { title: "Git", img: githubImg, type: "tools" },
  { title: "Python", img: pythonImg, type: "other" },
];

const Skills = () => {
  useScrollTop();
  const headings = ["frontend", "backend", "tools", "other"];

  return (
    <div className="h-full">
      <div className="bg-shadow-dark-blue py-4 text-center">
        <h2 className="text-slate-100 text-xl font-semibold">Skills</h2>
      </div>
      <div className="max-w-5xl mx-auto space-y-7 px-4 py-6">
        {headings.map((item) => (
          <Card key={item} heading={item} />
        ))}
      </div>
    </div>
  );
};

const Card = ({ heading }) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <span className="font-bold text-lg pb-2 capitalize text-light-blue">
          {heading}
        </span>
        <p className="grow border border-sky-100"></p>
      </div>
      <div className="flex flex-wrap gap-5 mt-3 pl-2">
        {skills.map((skill) => (
          <>
            {skill.type === heading && (
              <div
                key={skill.title}
                className="text-center space-y-1 border rounded-md overflow-hidden p-1 shadow-md"
              >
                <img src={skill.img} alt={skill.title} className=" w-16" />
                <p className="font-medium text-sm text-gray-700">
                  {skill.title}
                </p>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Skills;
