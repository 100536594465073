import React from "react";

const LinkButton = ({ children, href }) => {
  return (
    <button className="bg-shadow-dark-blue py-1 px-6 rounded-md font-medium text-gray-200 hover:text-white duration-500 uppercase shadow-md">
      <a href={href}>{children}</a>
    </button>
  );
};

export default LinkButton;
